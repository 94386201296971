import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import store from '@/store/index'
// 创建一个错误
function errorCreate(msg) {
    const error = new Error(msg)
    errorLog(error)
    throw error
}

// 记录和显示错误
function errorLog(error) {
    console.info(error)
    // 显示提示
    Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
    })
}

// 创建一个 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_API,

    // 请求超时时间
    timeout: 200000,

    // 请求头信息
    // headers: {'Content-Type': 'application/json,application/x-www-form-urlencoded','X-Requested-With': 'XMLHttpRequest'},

    //既可以传递json 同时可以传递form-data格式
    headers: { 'X-Requested-With': 'XMLHttpRequest' },

    //返回数据类型
    responseType: 'json', // default

    withCredentials: true // default
})

// 请求拦截器


// service.defaults.headers.common['Accept-Language'] = 'en-US'
let lang = localStorage.getItem('locale') ? localStorage.getItem('locale') === 'en' ? 'en-US' : (localStorage.getItem('locale') === 'cn' ? 'zh-CN' : 'zh-HK') : 'zh-CN'
service.defaults.headers.common['Accept-Language'] = lang  // 语言切换 en-US英文 zh-CN 中文
service.interceptors.request.use(
    config => {
        // 在请求发送之前做一些处理
        // const token = util.cookies.get('token')
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        // let token =  store.state.userInfoStore;
        let token = localStorage.getItem('specToken')
        if (!token) {
            token = "";
        } else {
            // config.headers['Authorization'] = "Bearer "+token;
            config.headers['token'] = token;
        }

        return config
    },
    error => {
        // 发送失败
        console.log(error)
        Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data ? response.data : response
        // 这个状态码是和后端约定的
        const { code } = dataAxios
        // 根据 code 进行判断

        // 判断response是否有token,如果有就覆盖
        if (response.headers.authorization !== undefined) {
            let token = response.headers['authorization'];
            store.state.userInfoStore.autoLogin ? localStorage.setItem('accessToken', token) : sessionStorage.setItem('accessToken', token);
        }

        if (code === undefined) {
            // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
            return dataAxios
        } else {
            // 有 code 代表这是一个后端接口 可以进行进一步的判断
            switch (code) {
                case 0:
                case '200':
                    // [ 示例 ] code === 0 代表没有错误
                    return dataAxios;
                case 200:
                    // [ 示例 ] code === 0 代表没有错误
                    return dataAxios;
                case '403':
                    {
                        Message({ message: response.data.message, type: 'error' });
                        router.replace({ 
                            path: "/login", //跳转登录页
                            query: {
                                redirect: localStorage.getItem('login_redirect') //记录当前是从哪里跳过去的   在登录后直接返回原来的页面
                            }
                        });
                        store.commit('clearUserInfo');
                        store.commit('clearToken');
                    }
                    break;
                case 'AUTH.00009':
                    {
                        throw dataAxios;
                    }
                // case 500:
                //     {
                //         throw dataAxios;
                //     }
                case 'xxx':
                    // [ 示例 ] 其它和后台约定的 code
                    // errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`)
                    break;
                default:
                    // 不是正确的 code
                    errorCreate(`${dataAxios.message}`);
                    break
            }
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400: error.message = '请求错误'; break
                case 401:
                    {
                        error.message = 'Unauthorized,please login';
                        router.push({ 
                            path: "/login", //跳转登录页
                            query: {
                                redirect: localStorage.getItem('login_redirect') //记录当前是从哪里跳过去的   在登录后直接返回原来的页面
                            }
                        });
                    }
                    break;
                case 403: error.message = '拒绝访问'; break
                case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
                case 408: error.message = '请求超时'; break
                case 500: error.message = '服务器内部错误'; break
                case 501: error.message = '服务未实现'; break
                case 502: error.message = '网关错误'; break
                case 503: error.message = '服务不可用'; break
                case 504: error.message = '网关超时'; break
                case 505: error.message = 'HTTP版本不受支持'; break
                default: break
            }
        }
        errorLog(error)
        return Promise.reject(error)
    }
)

export default service
