import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nameList: [],
    codeList: []
  },
  mutations: {
    setNameList(state, data) {
      data.forEach(v => {
        state.nameList.push(v);
      });
    },
    setCodeList(state, data) {
      data.forEach(v => {
        state.codeList.push(v);
      })
    }
  },
  actions: {
  },
  modules: {
  }
})
