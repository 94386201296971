let baseUrl = process.env.VUE_APP_API_BASE_URL;
let baseFileUrl = process.env.VUE_APP_File_BASE_URL;

export const loginUrl = baseUrl + 'api/login';
export const libraryPdfUrl = baseUrl + 'api/fileInfos/fileExist';

export const specTableUrl = baseUrl + 'api/spec/getPageList';
export const newSpecTableUrl = baseUrl + 'api/newSpec/getPageList';

export const handleSpecTableUrl = baseUrl + 'api/spec/info';
export const handleNewSpecTableUrl = baseUrl + 'api/newSpec/info';
export const handleNewSpecAuditedUrl = baseUrl + 'api/newSpecAudited/auditedList';

export const getInfoByItemCodes = baseUrl + 'api/spec/getInfoByItemCodes';

export const handleSpecDetailUrl = baseUrl + 'api/spec/detail';

export const addSpecItemUrl = baseUrl + 'api/spec/add';
export const addNewSpecItemUrl = baseUrl + 'api/newSpec/add';

export const editSpecItemUrl = baseUrl + 'api/spec/update';
export const editNewSpecItemUrl = baseUrl + 'api/newSpec/update';

export const checkItemCodeUrl = baseUrl + 'api/spec/check';

export const getPreviewUrl = baseUrl + 'api/spec/initData';

export const verfiedUrl = baseUrl + 'api/spec/verified';
export const newSpecVerifyUrl = baseUrl + 'api/newSpec/verify';

export const logoutUrl = baseUrl + 'api/logout';

export const deleteItemUrl = baseUrl + 'api/spec/delete';

export const copyItemUrl = baseUrl + 'api/spec/copy';

export const getItemCodeListUrl = baseUrl + 'api/spec/getItemCodeList';

export const getAllName = baseUrl + 'api/spec/getAllName';
export const getAllNameCount = baseUrl + 'api/spec/getAllNameCount';

export const batchUpdateItemCode = baseUrl + 'api/spec/batchUpdateItemCode';

export const updateDetailBySpecList = baseUrl + 'api/spec/updateList';
export const updateDetailBySdsList = baseUrl + 'api/sds/updateList';
export const updateDetailByCompList = baseUrl + 'api/compositionBreakdown/updateList';

export const sdsTableUrl = baseUrl + 'api/sds/getPageList';
export const addSdsItemUrl = baseUrl + 'api/sds/add';
export const editSdsItemUrl = baseUrl + 'api/sds/update';
export const handleSdsTableUrl = baseUrl + 'api/sds/info';
export const deleteSdsItemUrl = baseUrl + 'api/sds/delete';
export const verfiedSdsUrl = baseUrl + 'api/sds/verified';
export const copySdsUrl = baseUrl + 'api/sds/copy';
export const sdsMatchUrl = baseUrl + 'api/sds/match';
export const getSdsItemCodeListUrl = baseUrl + 'api/sds/getItemCodeList';

export const compTableUrl = baseUrl + 'api/compositionBreakdown/getPageList';
export const addCompItemUrl = baseUrl + 'api/compositionBreakdown/add';
export const editCompItemUrl = baseUrl + 'api/compositionBreakdown/update';
export const handleCompTableUrl = baseUrl + 'api/compositionBreakdown/info';
export const deleteCompItemUrl = baseUrl + 'api/compositionBreakdown/delete';
export const getCompItemCodeListUrl = baseUrl + 'api/compositionBreakdown/getItemCodeList';

export const copyCompUrl = baseUrl + 'api/comp/copy';
export const verfiedCompUrl = baseUrl + 'api/comp/verified';

export const otherTableUrl = baseUrl + 'api/tps/getPageList';
export const tpsUploadSaveUrl = baseUrl + 'api/tps/upload';
export const deleteTpsItemUrl = baseUrl + 'api/tps/delete';
export const getTpsItemCodeListUrl = baseUrl + 'api/tps/getItemCodeList';
export const tpsInfo = baseUrl + 'api/tps/info';

//获取分页列表
export const coaTableUrl = baseUrl + 'api/coa/getPageList';
//coa添加
export const addCoaUrl = baseUrl + 'api/coa/add';
//编辑更新coa
export const editCoaUrl = baseUrl + 'api/coa/update';
//获取某一个coa详情
export const handleCoaTableUrl = baseUrl + 'api/coa/info';

export const copyCoaItemUrl = baseUrl + 'api/coa/copy';
export const verfiedCoaItem = baseUrl + 'api/coa/verified';
//删除某一个coa
export const deleteCoaItemUrl = baseUrl + 'api/coa/delete';
export const batchDeleteUrl = baseUrl + 'api/coa/batch-delete';
//访问文件的地址
export const getFileUrl = baseFileUrl + 'file';
export const qiNiuUploadFile = baseUrl + 'api/upload/qiNiu'; //附件上传
export const libraryUploadFile = baseUrl + 'api/fileInfos/upload'; //Library附件上传
//获取文件管理所有文件
export const getFileManagementPageList = baseUrl + 'api/fileInfos/getPageList'; 
//批量添加文件
export const batchAddLibrary = baseUrl + 'api/fileInfos/batchAdd'; 
export const getLibraryInfo = baseUrl + 'api/fileInfos/info'; 
export const deleteLibraryInfo = baseUrl + 'api/fileInfos/delete'; 
export const deleteLibraryByItemcode = baseUrl + 'api/fileInfos/delete/itemCode'; 

export const uploadCoaItem=baseUrl+'api/coa/imp';
export const getItemCodeCoaListUrl = baseUrl + 'api/coa/getItemCodeList';


//获取中文列表的itemcode
export const getItemCodeCnListUrl = baseUrl + 'api/cnSpec/getItemCodeList';
//根据中文itemcode获取详细信息
export const getCnDetailByCnItemCode = baseUrl + 'api/cnSpec/detail';

//获取cnSpec分页列表
export const cnSpecTableUrl = baseUrl + 'api/cnSpec/getPageList';
//cnSpec添加
export const addCnSpecItemUrl = baseUrl + 'api/cnSpec/add';
//编辑更新cnSpec
export const editCnSpecItemUrl = baseUrl + 'api/cnSpec/update';
//获取某一个cnSpec详情
export const getCnSpecItemUrl = baseUrl + 'api/cnSpec/info';
//删除某一个cnSpec
export const deleteCnSpecItemUrl = baseUrl + 'api/cnSpec/delete';
//copy一个
export const copyCnSpecItemUrl = baseUrl + 'api/cnSpec/copy';
export const cnverfiedUrl = baseUrl + 'api/cnSpec/confirm';

//获取cnSds分页列表
export const cnSdsTableUrl = baseUrl + 'api/cnSds/getPageList';
//cnSds添加
export const addCnSdsItemUrl = baseUrl + 'api/cnSds/add';
//编辑更新cnSds
export const editCnSdsItemUrl = baseUrl + 'api/cnSds/update';
//获取某一个cnSpec详情
export const getCnSdsItemUrl = baseUrl + 'api/cnSds/info';
//删除某一个cnSds
export const deleteCnSdsItemUrl = baseUrl + 'api/cnSds/delete';
//根据substanceName获取已填写的信息
export const cnSdsMatchUrl = baseUrl + 'api/cnSds/match';
export const copyCnSdsUrl = baseUrl + 'api/cnSds/copy';
export const cnverfiedSdsUrl = baseUrl + 'api/cnSds/confirm';
//获取中文sds列表的itemcode
export const getItemCodeCnSdsListUrl = baseUrl + 'api/cnSds/getItemCodeList';
export const getCnDetailByCnSdsItemCode = baseUrl + 'api/cnSds/detail';

//原料安全相关信息相关接口
export const getCnRawmaterialList = baseUrl + 'api/cnRawmaterial/getPageList';
export const getCnRawmaterialInfo = baseUrl + 'api/cnRawmaterial/info';
export const addCnRawmaterial = baseUrl + 'api/cnRawmaterial/add';
export const updateCnRawmaterial = baseUrl + 'api/cnRawmaterial/update';
export const deleteCnRawmaterial = baseUrl + 'api/cnRawmaterial/delete';
export const getItemCodeCnRawmaterialList = baseUrl + 'api/cnRawmaterial/getProductNumberList';
export const getCnRawmaterialTargetNameInfo = baseUrl + 'api/cnRawmaterial/detail/targetName';

//中文coa相关接口
//获取分页列表
export const cnCoaTableUrl = baseUrl + 'api/cnCoa/getPageList';
//coa添加
export const addCnCoaUrl = baseUrl + 'api/cnCoa/add';
//编辑更新coa
export const editCnCoaUrl = baseUrl + 'api/cnCoa/update';
//获取某一个coa详情
export const getCnCoaInfo = baseUrl + 'api/cnCoa/info';
//删除某一个coa
export const deleteCnCoaItem = baseUrl + 'api/cnCoa/delete';
//获取中文coa列表的itemcode
export const getItemCodeCnCoaListUrl = baseUrl + 'api/cnCoa/getItemCodeList';
export const copyCnCoaItemUrl = baseUrl + 'api/cnCoa/copy';
export const cnCoaVerified = baseUrl + 'api/cnCoa/verified';
export const cnCoaComplete = baseUrl + 'api/cnCoa/complete';


//获取中文spec和英文spec的itemcode列表
export const getZhCnItemCodeList = baseUrl + 'api/spec/all_itemcodes';


//Conversion相关接口
export const conversionAdd = baseUrl + 'api/conversion/add';
export const conversionConfirm = baseUrl + 'api/conversion/confirm';
export const conversionDelete = baseUrl + 'api/conversion/delete';
export const getConversionList = baseUrl + 'api/conversion/getPageList';
export const conversionInfo = baseUrl + 'api/conversion/info';
export const conversionPreview = baseUrl + 'api/conversion/preview';
export const conversionUpdate = baseUrl + 'api/conversion/update';
//tds相关接口
export const tdsAdd = baseUrl + 'api/tds/add';
export const tdsConfirm = baseUrl + 'api/tds/confirm';
export const tdsDelete = baseUrl + 'api/tds/delete';
export const getTdsList = baseUrl + 'api/tds/getPageList';
export const tdsInfo = baseUrl + 'api/tds/info';
export const tdsUpdate = baseUrl + 'api/tds/update';
//testMethod相关接口
export const testMethodAdd = baseUrl + 'api/testMethod/add';
export const testMethodConfirm = baseUrl + 'api/testMethod/confirm';
export const testMethodDelete = baseUrl + 'api/testMethod/delete';
export const getTestMethodList = baseUrl + 'api/testMethod/getPageList';
export const getTestMethodConfirmCList = baseUrl + 'api/testMethod/confirmList';
export const testMethodInfo = baseUrl + 'api/testMethod/info';
export const testMethodUpdate = baseUrl + 'api/testMethod/update';


// Pdf 预览
export const pdfPreviewUrl = baseUrl + 'flask/generate_spec';