import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import api from './network/api.js';
import Print from 'vue-print-nb'
import VueClipboard from 'vue-clipboard2'

import axios from 'axios'
Vue.prototype.$axios = axios


Vue.use(VueClipboard)
Vue.use(Print);
Vue.config.productionTip = false
Vue.use(ElementUI, {locale})
Vue.prototype.$api = api;
Vue.prototype.$EventBus = new Vue()


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
